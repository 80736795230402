import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from "gatsby-image"



export const EfProductPageTemplate = ({productName, productBrand, productDescription, productImage, featuredImage, productRating, title, productId, previous, next, comments, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  let featuredImgFluid = featuredImage.childImageSharp.fluid
  return (

    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div style={{paddingBottom: "20px"}}><a href="/products/">Products</a><a href="# " style={{color: "black"}}> > {productName}</a></div>
            <div className="section">


              <div className="columns">
                  <div className="column is-2x" style={{padding: "0px", margin: "0px",backgroundColorx: "orange", align:"left"}}>
                  <Img fluid={featuredImgFluid}  style={{padding: "0px", margin: "0px", backgroundColorx: "orange", align:"left"}}/>
                  </div>
                  <div style={{textAlign: 'left', backgroundColorx: "#800000", positionx: "relative", top:"0px"}} className="column is-2x">
                  <h3 className="title is-size-3x has-text-weight-bold is-bold-light">
                    {productName}

                  </h3>
                      by {productBrand}

                      <div>
                      <div>




{/*
                      <div style={{marginTop: "10px", paddingBottom: "0px"}}>


                      {parseFloat(productRating) >=0 && parseFloat(productRating) < .25 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                               31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                               12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                               fill="white" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) >= 0.25 && parseFloat(productRating) <= .5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <defs>
                              <linearGradient id="half_grad">
                                <stop offset="50%" stop-color="blue"/>
                                <stop offset="50%" stop-color="white" stop-opacity="1" />
                              </linearGradient>
                            </defs>
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="url(#half_grad)" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) > .5 &&
                          <span style={{padding:"2px"}}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                              <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                   31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                   12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                   fill="blue" stroke-width="1" stroke="blue"/>
                            </svg>
                          </span>
                      }


                      {parseFloat(productRating) < 1.25 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                               31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                               12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                               fill="white" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) >= 1.25 && parseFloat(productRating) <= 1.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <defs>
                              <linearGradient id="half_grad">
                                <stop offset="50%" stop-color="blue"/>
                                <stop offset="50%" stop-color="white" stop-opacity="1" />
                              </linearGradient>
                            </defs>
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="url(#half_grad)" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) > 1.5 &&
                          <span style={{padding:"2px"}}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                              <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                   31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                   12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                   fill="blue" stroke-width="1" stroke="blue"/>
                            </svg>
                          </span>
                      }

                      {parseFloat(productRating) < 2.25 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                               31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                               12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                               fill="white" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) >= 2.25 && parseFloat(productRating) <= 2.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <defs>
                              <linearGradient id="half_grad">
                                <stop offset="50%" stop-color="blue"/>
                                <stop offset="50%" stop-color="white" stop-opacity="1" />
                              </linearGradient>
                            </defs>
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="url(#half_grad)" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) > 2.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="blue" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }


                      {parseFloat(productRating) < 3.25 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                               31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                               12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                               fill="white" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) >= 3.25 && parseFloat(productRating) <= 3.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <defs>
                              <linearGradient id="half_grad">
                                <stop offset="50%" stop-color="blue"/>
                                <stop offset="50%" stop-color="white" stop-opacity="1" />
                              </linearGradient>
                            </defs>
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="url(#half_grad)" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) > 3.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="blue" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }


                      {parseFloat(productRating) < 4.25 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                               31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                               12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                               fill="white" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) >= 4.25 && parseFloat(productRating) < 4.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <defs>
                              <linearGradient id="half_grad">
                                <stop offset="50%" stop-color="blue"/>
                                <stop offset="50%" stop-color="white" stop-opacity="1" />
                              </linearGradient>
                            </defs>
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="url(#half_grad)" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }
                      {parseFloat(productRating) >= 4.5 &&
                        <span style={{padding:"2px"}}>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                            <path d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
                                 31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,
                                 12.118l11.547-1.2L16.026,0.6L20.388,10.918z"
                                 fill="blue" stroke-width="1" stroke="blue"/>
                          </svg>
                        </span>
                      }


                      <span style={{padding:"10px"}}>6 ratings</span>



                      </div>


*/}





                      <div style={{marginTop: "15px", paddingBottom: "20px"}}>{productDescription}</div>


                      </div>
                        <ul>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>Low Sugar</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>Low Sodium</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>Low Fat</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>Low Cholesterol</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>Low Carb</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>High Fiber</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>No MSG</b></li>
                          <li><a href="# " style={{color: "green", fontSize: "24px", marginRight: "10px"}}>&#10003;</a><b>Clean Ingredients</b></li>
                        </ul>
                      </div>
                  </div>
              </div>



              <PageContent className="content" content={content}/>






              <div>
              <h4 className="title is-size-3">Reviews</h4>
              <div>

  <Link className="btn" to={"/forms/comment-product?product_id=" + productId} style={{marginTop: "10px", paddingTop: "9px", width: "210px", paddingx: "10px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
    Review
  </Link>
  <p><br></br></p>
  </div>

  




              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

EfProductPageTemplate.propTypes = {
  productName: PropTypes.string.isRequired,
  productBrand: PropTypes.string.isRequired,
  productDescription: PropTypes.string,
  productImage: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  productRating: PropTypes.string,
  title: PropTypes.string.isRequired,
  productId: PropTypes.string,
  previous: PropTypes.string,
  next: PropTypes.string,
  comments: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const EfProductPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <EfProductPageTemplate
        contentComponent={HTMLContent}
        productName={post.frontmatter.productName}
        productBrand={post.frontmatter.productBrand}
        productDescription={post.frontmatter.productDescription}
        productImage={post.frontmatter.productImage}
        featuredImage={post.frontmatter.featuredImage}
        productRating={post.frontmatter.productRating}
        title={post.frontmatter.title}
        productId={post.frontmatter.productId}
        previous={post.frontmatter.previous}
        next={post.frontmatter.next}
        comments={post.frontmatter.comments}
        content={post.html}
      />
    </Layout>
  )
}

EfProductPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default EfProductPage

export const efProductPageQuery = graphql`
  query EfProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        productId
        productName
        productBrand
        productDescription
        productImage
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        productRating
        title
        pageId
        previous
        next
        comments{
          member
          text
          rating
          commentId
          parentCommentId
        }
      }
    }
  }
`
